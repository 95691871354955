.footer-container {
  display: flex;
  flex-direction: column;
  text-align: center;

  padding: 25px 25px 50px;

  font-size: 24px;
  color: var(--purple);
  font-family: 'Montserrat';

  a {
    color: var(--purple);
    text-decoration: none;

    margin: 35px auto 0;
    padding-bottom: 7px;

    border-bottom: 1px solid var(--purple);
  }
}

@media(max-width: 800px) {
  .footer-container {
    font-size: 20px;
  }
}
