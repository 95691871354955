.my-work-container {

  display: flex;
  flex-direction: column;

  align-items: center;

  padding: 50px 0 0 0;

  .title {
    font-size: 48px;
    color: var(--purple);
    font-family: 'Montserrat';

    padding-bottom: 10px;
    border-bottom: 2px solid var(--gray);
  }

  .bubble-container {
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
    justify-content: space-evenly;

    padding-top: 25px;
  }
}

@media(max-width: 800px) {
  .my-work-container {
    .title {
      font-size: 40px;
    }
  }
}