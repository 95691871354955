.interactive-stars-container {
  position: relative;
  width: 100%;

  background: black;

  overflow: hidden;
}

.no-cursor {
  cursor: none;
}
