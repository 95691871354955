.button-container {
  font-family: 'Roboto';
  color: #fff;
  font-size: 18px;

  cursor: pointer;
  transition: 0.15s all ease-in-out;
  text-decoration: none;

  border: none;
  border-radius: 30px;
  padding: 15px 30px;
  margin-top: 50px;

  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--light-purple)), to(var(--purple)));
  background-image: -webkit-linear-gradient(top, var(--light-purple), var(--purple));
  background-image: -o-linear-gradient(top, var(--light-purple), var(--purple));
  background-image: linear-gradient(to bottom, var(--light-purple), var(--purple));
}