@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Regular.ttf) format('truetype');
}

html {
  --light-gray: #9b9b9b;
  --gray: #7c7c7c;
  --white: #ffffff;
  --purple: #492f9f;
  --light-purple: #492f9fa7;
  --red: #e20000;
}

body {
  margin: 0;
  padding: 0;

  background-color: var(--white);

  color: var(--gray);
  font-family: "Roboto";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.max-width {
  max-width: 1200px;
  margin: auto;
}