.contact-container {
  display: flex;
  flex-direction: column;

  align-items: center;

  padding: 25px 0 50px;

  .paper-container {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: 400px;

    padding: 50px;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.25);
    border-radius: 12px;

    margin: 0 10px;

    .submit-screen {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      padding: 0 75px;

      background: rgba(73, 47, 159, .65);
      border-radius: 12px;

      font-size: 36px;
      font-family: 'Montserrat';
      color: var(--white);

      text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
      text-align: center;
    }

    .title {
      font-size: 40px;
      color: var(--purple);
      font-family: 'Montserrat';

      padding-bottom: 10px;
      margin-bottom: 15px;
      border-bottom: 2px solid var(--gray);
    }
  }
}

@media(max-width: 600px) {
  .contact-container {
    .paper-container {
      width: 80%;

      padding: 50px 25px;

      .title {
        font-size: 36px;
      }

      .submit-screen {
        padding: 0 25px;
      }
    }
  }
}