.typing-text-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: fit-content;
  height: 100px;
  margin: auto;

  font-family: 'Montserrat';
  font-size: 48px;
  text-align: center;

  user-select: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .typewrite {

    padding: 25px 50px;

    text-shadow: 0 2px 5px rgba(255, 255, 255, 0.25);

    .typing-text-static {
      color: var(--gray);
    }

    .wrap {
      color: var(--purple);
    }

    .blink-cursor {
      font-size: 56px;
      color: var(--purple);

      animation: blinkCursor .75s steps(1) infinite;
    }
  }
}

@keyframes blinkCursor {
  50% {
    color: transparent;
  }
}

@media(max-width: 800px) {
  .typing-text-container {
    flex-direction: column;

    font-size: 40px;
  }
}
